
$(document).ready(function () {
    baseFunc.init();
    mainSlider.init();
    //socialShare.init();
    //courseFunc.init();
    //userFunc.init();
    //$(`#profileId`).val(),
    //const fullName = document.getElementById('fullname').textContent;
    //const intials = fullName.split(' ')[fullName.split(' ').length-1][0].toUpperCase();
    //document.getElementById("profileImage").innerHTML = intials;
    //document.getElementById("profileImage").style.backgroundColor = stringToHslColor(fullName);
    const post = document.querySelectorAll('#forum-post-detail').forEach(elem =>
    //const name = elem.getElementsByClassName('fullname')[0].textContent;
    //console.log(elem.getElementsByClassName('fullname'))
    //console.log("dsf")
    {
        for (let i = 0; i < elem.getElementsByClassName('fullname').length; i++) {
            //console.log(document.getElementsByClassName('profileImage')[i].style.backgroundColor)
            var name = elem.getElementsByClassName('fullname')[i].innerText;
            document.getElementsByClassName('profileImage')[i].style.backgroundColor = stringToHslColor(name);
            document.getElementsByClassName('profileImage')[i].innerText = name.split(' ')[name.split(' ').length - 1].charAt(0)
        }
    }
        
        //elem.getElementsByClassName('fullname').forEach(a => console.log(a.textContent))
        
    );
    //var fourChildNode = post.querySelector('.accrodion');
    //for (var i = 0; i < post.childNodes.length; i++) {
    //    console.log(post.childNodes[i]);
    //    if (post.childNodes[i].className == "accrodion") {
    //        //var name = post.childNodes[i].querySelector('.fullname').childNodes[0].val();
    //        console.log(post.childNodes[i]);
    //        //post.childNodes[i].querySelector('.profileImage').style.backgroundColor = stringToHslColor(name);
    //    }
        
    //}
    //post.childNodes.forEach(o => {
        
    //    if (post.getAttribute('class') == 'profileImage') {
    //        console.log(o.innerHtml);
    //    }
    //})
    // get all children
    //document.getElementById("youtubeimg").src;
   
});

function stringToHslColor(str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    var h = hash % 360;
    return 'hsl(' + h + ', 50%, 70%)';
}
function downloadPDF(tenHocVien, tenKhoaHoc, ngayHoanThanh) {
    //console.log(tenHocVien, tenKhoaHoc, ngayHoanThanh);
    const dataPost = {
        listData: [
            {
                tenHocVien: tenHocVien,
                tenKhoaHoc: tenKhoaHoc,
                ngayHoanThanh
            }
        ]
    };

    swalShowLoading("Đang tải chứng nhận...");

    $.ajax({
        url: "https://report.sc.edu.vn/api/v1/Report/ExportRtccdPdf",
        //url: "http://localhost:5050/api/v1/Report/ExportRtccdPdf",
        type: "POST",
        contentType: 'application/json',
        data: JSON.stringify(dataPost),
    }).done((data) => {
        const linkSource = `data:application/pdf;base64,${data}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = `Chứng nhận khóa học ${tenKhoaHoc}`;
        downloadLink.click();
        swalCloseLoading();

    }).fail((data) => {
        mySwal(SWAL_CONST.TYPE.ERR, data.responseJSON.msg);
    });
   
};
///**
// * Các hàm cơ bản cần dùng
// * */
var baseFunc = {
    init: function () {
        this.pagination();
        //this.sendLessonFinish();
        //this.removeVideoIfrAttr();
        //this.searchNews();
        this.sendComment();
        this.sendPost();
        //this.doRateLesson();
        
    },
    
//    // Phân trang
    pagination: function () {

        const myPagination = $('.my-pagination');
        if (myPagination.length > 0) {
            const totalItem = parseInt(myPagination.attr("data-total"));
            const currentPage = parseInt(myPagination.attr("data-current-page"));
            const urlBase = myPagination.attr("data-url");
            const pageSize = parseInt(myPagination.attr("data-pagesize"));

            myPagination.pagination({
                items: totalItem,
                currentPage: currentPage,
                itemsOnPage: pageSize,
                displayedPages: 3,
                cssStyle="count",
                prevText: '<span><i class="fa fa-angle-left"></i></span>',
                nextText: '<span><i class="fa fa-angle-right"></i></span>',
                hrefTextPrefix: urlBase,
                onPageClick: function (page, e) {
                }
            })
        }
    },

//    // Tìm kiếm tin tức
//    searchNews: function () {
//        const form_name = "#news_search_form";
//        if ($(form_name).length > 0) {
//            var fv = $(form_name).formValidation({
//                framework: 'bootstrap',
//                fields: {
//                    keyword: {
//                        validators: {
//                            notEmpty: {
//                                message: 'Từ khóa tìm kiếm không được để trống'
//                            }
//                        }
//                    },

//                }
//            }).on('success.form.fv', function (e) {

//                e.preventDefault();

//                const keyword = $(`${form_name} #keyword`).val()

//                location.href = `/tim-tin-tuc/${keyword}/page-1`;

//            });
//        }
//    },


//    // Gửi đánh giá + bình luận cuối khóa
//    sendLessonFinish: async function () {
//        $("#jsBtnLessonFinish").click(function () {
//            const rating = $(".lesson-congrat .star-rating").val();
//            const comment = $(".lesson-congrat #comment_message").val();
//            const courseId = $(".lesson-congrat #jsCourseId").val();
//            if (!rating) {
//                mySwal(SWAL_CONST.TYPE.WARNING, "Bạn chưa đánh giá khóa học");
//                return;
//            }
//            if (!comment) {
//                mySwal(SWAL_CONST.TYPE.WARNING, "Bạn chưa bình luận khóa học");
//                return;
//            }
//            if (!courseId) {
//                mySwal(SWAL_CONST.TYPE.WARNING, "Không thể thực hiện");
//                return;
//            }

//            const dataPost = {
//                rating,
//                comment,
//                courseId
//            };

//            swalShowLoading("Đang thực hiện...");

//            $.ajax({
//                url: "/StudyCourse/CommentRatingFinishCourse",
//                type: "POST",
//                contentType: 'application/json',
//                data: JSON.stringify(dataPost),
//            }).done((data) => {
//                mySwal(SWAL_CONST.TYPE.SUCCESS, "Đánh giá thành công");
//                setTimeout(() => {
//                    location.reload();
//                }, 500);

//            }).fail((data) => {
//                mySwal(SWAL_CONST.TYPE.ERR, data.responseJSON.msg);
//            });

//        })
//    },

//    doRateLesson: function () {
//        const self = this;
       
//        $("#jsBtnCustomerReview").click(function () {     
//            const ratePoint = $("#jsCustomerReview #jsRateLesson").val();
//            const comment = $("#jsCustomerReview #comment_message").val();
//            const lessonId = $("#jsCustomerReview #jsLessonId").val();

//            if (!ratePoint) {
//                mySwal(SWAL_CONST.TYPE.WARNING, "Bạn chưa đánh giá bài học");
//                return;
//            }
//            if (!comment) {
//                mySwal(SWAL_CONST.TYPE.WARNING, "Bạn chưa bình luận cho bài học");
//                return;
//            }
//            if (!lessonId) {
//                mySwal(SWAL_CONST.TYPE.WARNING, "Không thể thực hiện");
//                return;
//            }

//            const dataPost = {
//                ratePoint,
//                comment,
//                lessonId
//            };

//            swalShowLoading("Đang thực hiện...");

//            $.ajax({
//                url: "/LessonRate/DoRate",
//                type: "POST",
//                contentType: 'application/json',
//                data: JSON.stringify(dataPost),
//            }).done((data) => {
//                swalCloseLoading();
//                $("#jsCustomerReviewContent").html(data);

//                const customerCoureId = $("#jsCustomerCourseId").val();

//                doGetCompleteButton(lessonId, customerCoureId);

//                const starElm = $(".star-rating");
//                if (starElm.length > 0) {
//                    starElm.each(function () {
//                        const starPercent=  $(this).find("span").attr("data-width");
//                        $(this).find("span").attr("style", `width:${starPercent}`);                  
//                    });       
//                }
               

//            }).fail((data) => {
//                mySwal(SWAL_CONST.TYPE.ERR, data.responseJSON.msg);
//            });

//        })
//    },

//    // Xóa thuộc tính Attr khi nhúng Video Iframe
//    removeVideoIfrAttr: function () {
//        $(".fluid-width-video-wrapper").removeClass("fluid-width-video-wrapper").addClass("ejol-lesson-video").removeAttr("style");
//    },

    // Gửi bình luận
    sendComment: function () {
        const form_name = "#forum_comment_form";
        if ($(form_name).length > 0) {
            var fv = $(form_name).formValidation({
                framework: 'bootstrap',
                fields: {
                    comment_message: {
                        validators: {
                            notEmpty: {
                                message: 'Nội dung không được để trống'
                            }
                        }
                    },

                }
            }).on('success.form.fv', function (e) {

                e.preventDefault();
                $(".post-popup").toggleClass("active");
                $(".mobile-nav__wrapper").removeClass("expanded");
                $("body").toggleClass("locked");
                const dataPost = {
                    fp_id: $(`${form_name} #fp_id`).val(),
                    fcm_content: $(`${form_name} #comment_message`).val(),
                };

                swalShowLoading("<p>Đang xử lý...</p>")

                $.ajax({
                    url: "/Forum/CreateComment",
                    type: "POST",
                    data: dataPost,
                }).done((data) => {
                    swalCloseLoading();
                    //$(`#course_comments_area`).html(data);
                    $(form_name).trigger('reset');
                    $(form_name).data('formValidation').resetForm();
                    mySwal(SWAL_CONST.TYPE.SUCCESS, "Gửi bình luận thành công, vui lòng chờ admin duyệt");
                }).fail((data) => {
                    mySwal(SWAL_CONST.TYPE.WARNING, "Bình luận thất bại");
                    $(form_name).data('formValidation').resetForm();
                });



            });
        }
    },

    // Gửi bình luận
    sendPost: function () {
        const form_name = "#forum_post_form";
        if ($(form_name).length > 0) {
            var fv = $(form_name).formValidation({
                framework: 'bootstrap',
                fields: {
                    //content_message: {
                    //    validators: {
                    //        notEmpty: {
                    //            message: 'Nội dung không được để trống'
                    //        }
                    //    }
                    //},
                    //title_message: {
                    //    validators: {
                    //        notEmpty: {
                    //            message: 'Tiêu đề không được để trống'
                    //        }
                    //    }
                    //},
                }
            }).on('success.form.fv', function (e) {

                e.preventDefault();
                $(".post-popup").toggleClass("active");
                $(".mobile-nav__wrapper").removeClass("expanded");
                $("body").toggleClass("locked");
                const dataPost = {
                    fc_id: $(`${form_name} #fc_id`).val(),
                    fp_title: $(`${form_name} #title_message`).val(),
                    fp_content: $(`${form_name} #content_message`).val(),
                };

                swalShowLoading("<p>Đang xử lý...</p>")

                $.ajax({
                    url: "/Forum/CreatePost",
                    type: "POST",
                    data: dataPost,
                }).done((data) => {
                    swalCloseLoading();
                    //$(`#course_comments_area`).html(data);
                    $(form_name).trigger('reset');
                    $(form_name).data('formValidation').resetForm();
                    mySwal(SWAL_CONST.TYPE.SUCCESS, "Đăng bài thành công, vui lòng chờ admin duyệt bài");
                }).fail((data) => {
                    mySwal(SWAL_CONST.TYPE.WARNING, "Đăng bài thất bại");
                    $(form_name).data('formValidation').resetForm();
                });



            });
        }
    },

};


/**
 * Các hàm liên quan đến share
 * */
var socialShare = {
    init: function () {
        this.facebookShare();
    },
    facebookShare: function () {
        const fbButton = $("#fb-share-button");
        fbButton.click(function () {

            // Nếu lấy đường link của trang hiện tại(trang chứa nút chia sẻ)
            const isCurrentLink = $(this).attr("data-is-current-link");

            let url = window.location.href;

          

            if (!isCurrentLink) {
                url = $(this).attr("data-href");
            }

            if (url) {
                window.open('https://www.facebook.com/sharer/sharer.php?u=' + url,
                    'facebook-share-dialog',
                    'width=800,height=600'
                );
            }

            return false;
        });
    }
}


var mainSlider = {
    init: function () {
        this.homeSlider();
        this.courseSlider();
        this.gallerySlider();
    },
    homeSlider: function () {
        const elm = $("#main-slider");
        if (elm.length <= 0) return;
        elm.lightSlider({
            adaptiveHeight: false,
            item: 1,
            slideMargin: 0,
            loop: true,
            auto: true,
            mode: 'fade',
            pager: false,
            pause: 5000,
            speed: 2000,
            controls: false
        });
    },
    courseSlider: function () {
        const elm = $("#course-slide");
        if (elm.length <= 0) return;
        elm.lightSlider({
            adaptiveHeight: false,
            item: 3,
            slideMargin: 15,
            loop: true,
            auto: true,
            speed: 600,
            pause: 3000,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        item: 2,
                        slideMove: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        item: 1,
                        slideMove: 1
                    }
                }
            ]
        });
    },
    gallerySlider: function () {
        const elm = $(".sc-slider");
        if (elm.length <= 0) return;
        elm.lightSlider({
            gallery: true,
            item: 1,
            loop: true,
            thumbItem: 6, 
            slideMargin: 0,
            auto: true,
            mode: 'fade',
            speed: 1600
        });
    }
};








