
$(document).ready(function () {
    doCloseTestPopup();

    // Ajax Answer
    //doActiveAnswer();
    //doMultiActiveAnswer();
    //doAnswerMulti();
});

const TEST_TYPE = {
    BAI_KHAO_SAT: 1,
    TI_LE: 2,
    TINH_DIEM: 3,
    CUOI_KHOA: 4,
    NHAP_DAP_AN: 5
}


/**=============================================
 * Kiểm tra xem bài học có bài kiểm tra không
 * @param {number} lessonId: id bài học
 * @param {number} customerCourseId: id khóa học khách hàng đăng ký
 =============================================*/
function doCourseTest(lessonId, customerCourseId) {
    const dataPost = {
        lessonId
    };

    swalShowLoading("Đang thực hiện...");

    $.ajax({
        url: "/StudyCourse/CheckTest",
        type: "POST",
        contentType: 'application/json',
        data: JSON.stringify(dataPost), 
    }).done((data) => {
        if (data.isTest) {
            doGetPopupTest(lessonId, customerCourseId);
            doGetCompleteButton(lessonId, customerCourseId);
            swalCloseLoading();
            return;
        }
        if (data.nextLessonUrl) {
            location.href = data.nextLessonUrl;
            return;
        }

        swalCloseLoading();
        const dataPost = {
            courseId: data.courseId
        };

        $.ajax({
            url: "/StudyCourse/GetLastLessonRating",
            type: "POST",
            contentType: 'application/json',
            data: JSON.stringify(dataPost),
        }).done((data) => {
          
            $("#jsLessonContent").html(data);
           
            baseFunc.sendLessonFinish();
            baseFunc.initRating();

        }).fail((data) => {
            mySwal(SWAL_CONST.TYPE.ERR, data.responseJSON.msg);
        });
        
    }).fail((data) => {
        mySwal(SWAL_CONST.TYPE.ERR, data.responseJSON.msg);
    });

}

/**=============================================
 * Lấy html popup thực hiện bài kiểm tra
 * @param {number} lessonId: id bài học
 =============================================*/
function doGetPopupTest(lessonId) {
    swalShowLoading("Đang thực hiện...");
    const dataPost = {
        lessonId
    };

    $.ajax({
        url: "/StudyCourse/GetPopupTest",
        type: "POST",
        contentType: 'application/json',
        data: JSON.stringify(dataPost),
    }).done((data) => {
        swalCloseLoading();
        $("#ajaxTestModal").html(data);
        $('#doTestModal').modal('show');
        multiAnswerFunc.init();

    }).fail((data) => {
        mySwal(SWAL_CONST.TYPE.ERR, data.responseJSON.msg);
    });
}


/**=============================================
 * Lấy html các nút (Quay lại, Hoàn thành, Tiếp) trong bài học
 * @param {number} lessonId: id bài học
 * @param {number} customerCourseId: id khóa học khách hàng đăng ký
 =============================================*/
function doGetCompleteButton(lessonId, customerCourseId) {
    const dataPost = {
        lessonId,
        customerCourseId
    };
    $.ajax({
        url: "/StudyCourse/GetCompleteButton",
        type: "POST",
        contentType: 'application/json',
        data: JSON.stringify(dataPost),
    }).done((data) => {
        $("#btn-completed-course").html(data);
    }).fail((data) => {
        console.log(data.responseJSON.msg);
    });
}

/**=============================================
 * Trả lời câu hỏi loại chỉ có 1 đáp án
 * @param {number} answerId: id câu trả lời
 * @param {number} testQuestionId: id câu hỏi
 * @param {number} lessonId: id bài học
 =============================================*/
function doAnswer(answerId, testQuestionId, lessonId) {
    //const testType = $("#jsTestType").val();

    swalShowLoading("Đang kiểm tra...");
    const dataPost = {
        answerId,
        testQuestionId,
        lessonId
    };
    $.ajax({
        url: "/StudyCourse/DoAnswerQuestion",
        type: "POST",
        contentType: 'application/json',
        data: JSON.stringify(dataPost),
    }).done((data) => {

        doGetNextQuestion(testQuestionId, lessonId);

    }).fail((data) => {
        mySwal(SWAL_CONST.TYPE.ERR, data.responseJSON.msg);
    });
}


/**==========================================
 * Lấy câu hỏi tiếp theo
 * @param {number} testQuestionId: id câu hỏi
 * @param {number} lessonId: id bài học
 ==========================================*/
function doGetNextQuestion(testQuestionId, lessonId) {
    swalShowLoading("Câu tiếp theo");
    const dataPost = {
        testQuestionId,
        lessonId
    };
    $.ajax({
        url: "/StudyCourse/GetNextQuestion",
        type: "POST",
        contentType: 'application/json',
        data: JSON.stringify(dataPost),
    }).done((data) => {
        setTimeout(function () {
            swalCloseLoading();
            //===>Views/Share/Components/DoTestModal
            $("#lessonQuestion").html(data);
            multiAnswerFunc.init();
        }, 500);

    }).fail((data) => {
        mySwal(SWAL_CONST.TYPE.ERR, data.responseJSON.msg);
    });
}

/**==========================================
 * Lấy kết quả bài kiểm tra
 * @param {number} testId: id bài kiểm tra
 * @param {number} lessonId: id bài học
 ==========================================*/
function doGetTestResult(testId, lessonId) {
    const dataPost = {
        testId,
        lessonId
    };
    $.ajax({
        url: "/StudyCourse/GetTestResult",
        type: "POST",
        contentType: 'application/json',
        data: JSON.stringify(dataPost),
    }).done((data) => {

        //===>Views/Share/Components/DoTestModal
        $("#lessonQuestion").html(data);
    }).fail((data) => {
        mySwal(SWAL_CONST.TYPE.ERR, data.responseJSON.msg);
    });
}

/**==========================================
 * Đóng popup bài học lại
 ==========================================* */
function doCloseTestPopup() {
    $("body").on("click", "#btnEndTest", function () {
        const isClose = $("#btnEndTest").attr("data-isclose");
        if (isClose) {
            const isFinist = $("#btnEndTest").attr("data-isfinish");
            $('#doTestModal').modal('hide');
            if (isFinist) {
                location.reload();
            }
        }
        else {
            const testId = $("#modal-test-id").val();
            const lessonId = $("#modal-lesson-id").val();

            mySwal(SWAL_CONST.TYPE.WARNING, "Bài kiểm tra sẽ kết thúc ngay lập tức! Bạn chắc chứ ?", true).then(swalRes => {
                if (swalRes.isConfirmed) {
                    doGetTestResult(testId, lessonId);
                }

            });
            //$('#doTestModal').modal('hide');
        }
    });

}


//#region Các hàm liên quan đến việc chọn nhiều câu hỏi cùng 1 lúc

var multiAnswerFunc = {
    init: function () {

        this.doAnswerMulti();
        this.doActiveAnswer();
        this.doMultiActiveAnswer();
    },

    /**===========================================
    * Thực hiện trả lời nhiều câu trả lời cùng 1 lúc
    ===========================================* */
    doAnswerMulti: function () {
        $("#jsBtnAnswer").click(function () {

            const testType = $("#jsTestType").val();
            const testId = $("#modal-test-id").val();
            const testQuestionId = $('#jsTestQuesionId').val();
            const lessonId = $('#jsLessonId').val();

           
            const listChooseId = $("#jsTestListChoose").val().split(".").filter(o => o) || [];
            const textAnswer = $("#jsTextAnswer").val();
            if (testType == TEST_TYPE.NHAP_DAP_AN) {
                if (!textAnswer) {
                    mySwal(SWAL_CONST.TYPE.WARNING, "Bạn chưa nhập đáp án !");
                    return;
                }
                if (textAnswer < 0) {
                    mySwal(SWAL_CONST.TYPE.WARNING, "Đáp án không thể nhỏ hơn 0 !");
                    return;
                }

            }
            else {
                if (listChooseId.length === 0) {
                    mySwal(SWAL_CONST.TYPE.WARNING, "Bạn chưa chọn đáp án nào!");
                    return;
                }
            }
            

            const dataPost = {
                listAnswerId: listChooseId,
                testQuestionId,
                testId,
                lessonId,
                isMulti: true,
                testType,
                textAnswer
            };

            $.ajax({
                url: "/StudyCourse/DoAnswerQuestion",
                type: "POST",
                contentType: 'application/json',
                data: JSON.stringify(dataPost),
            }).done((data) => {

                if (testType == TEST_TYPE.BAI_KHAO_SAT || testType == TEST_TYPE.NHAP_DAP_AN) {
                    doGetNextQuestion(testQuestionId, lessonId);

                } else {
                    let type = SWAL_CONST.TYPE.ERR, msg = "Sai rồi";
                    if (data.isCorrect) {
                        type = SWAL_CONST.TYPE.SUCCESS;
                        msg = "Chính xác";
                    }

                    mySwalTimer(type, msg, 1500).then(res => {
                        if (res.dismiss === Swal.DismissReason.timer) {
                            doGetNextQuestion(testQuestionId, lessonId);
                        }
                    });
                }

            }).fail((data) => {
                mySwal(SWAL_CONST.TYPE.ERR, data.responseJSON.msg);
            });
        });
    },

    /**===========================================
    * Active những câu được chọn
    ===========================================* */
    doActiveAnswer: function () {
        const elm = $("#doTestModal .answer-list .answer.single");
        elm.click(function () {

            elm.removeClass("active");
            $(this).addClass("active");
        });
    },

    /**===========================================
    * Lưu giá trị những câu trả lời đc chọn
    ===========================================* */
    doMultiActiveAnswer: function () {
        const elm = $("#doTestModal .answer-list .answer.multi");
        const isMultiCorrect = $("#jsIsMultiCorrectAnswer").attr("data-value");
        elm.click(function () {
            const ta_id = $(this).attr('data-taId');
            const listChooseId = $("#jsTestListChoose").val();
            if (isMultiCorrect == 'True') {
                if (!$(this).hasClass("active")) {

                    $(this).addClass("active");

                    if (listChooseId) {
                        $("#jsTestListChoose").val(`${listChooseId}.${ta_id}`);
                    }
                    else {
                        $("#jsTestListChoose").val(ta_id);
                    }
                }
                else {
                    $(this).removeClass("active");
                    let arr = listChooseId.split(".");
                    arr = arr.filter(o => o != ta_id);
                    let stringParams = "";
                    for (let i = 0; i < arr.length; i++) {
                        if (i == 0) {
                            stringParams += `${arr[i]}`;
                        } else {
                            stringParams += `.${arr[i]}`;
                        }
                    }
                    $("#jsTestListChoose").val(stringParams);
                }
            }
            else {
                $("#jsTestListChoose").val(ta_id);
                if(!$(this).hasClass("active")){
                    elm.removeClass('active');
                    $(this).addClass("active");
                }
            }



        });
    }
}

//#endregion